/**
 * Muval CRM Navigation Helper
 *
 * Abstracts the setting of navigation objects depending on user role
 *
 * @package Muval CRM
 */

import { uniqBy } from 'lodash';

const superNav = [
    { name: 'Leads', route: '/orders', icon: 'large-Magnet', current: false },
    {
        name: 'Jobs',
        route: '/jobs',
        icon: 'large-Box-stack',
        current: false,
        subitems: [
            { name: 'Move jobs', route: '/jobs', icon: 'large-Box-stack', current: false },
            { name: 'Ancillary only', route: '/ancillary', icon: 'large-ancillary', current: false },
        ],
    },
    {
        name: 'Finance',
        route: '/finance',
        icon: 'large-Cash',
        current: false,
        subitems: [
            {
                name: 'Transactions',
                route: '/finance',
                icon: 'large-Cash',
                current: false,
            },
            { name: 'Payouts', route: '/payouts', icon: 'payouts', current: false },
            { name: 'Coupons', route: '/coupons', icon: 'coupons', current: false },
        ],
    },
    {
        name: 'Companies',
        route: '/companies',
        icon: 'large-Truck-front',
        current: false,
    },
    {
        name: 'Interstate',
        route: '/interstate',
        icon: 'large-Map',
        current: false,
    },
    { name: 'Depots', route: '/depots', icon: 'large-Shed', current: false },
    { name: 'Local', route: '/local', icon: 'large-Pin', current: false },
    { name: 'Fleet', route: '/fleet', icon: 'large-Truck-side', current: false },
    { name: 'Staff', route: '/staff', icon: 'large-Movers', current: false },
    {
        name: 'Affiliates',
        route: '/affiliates',
        icon: 'large-group-of-people',
        current: false,
    },
    {
        name: 'Providers',
        route: '/providers',
        icon: 'large-Movers',
        current: false,
    },
    {
        name: 'Communication',
        route: '/communication',
        icon: 'large-Chat',
        current: false,
    },
    {
        name: 'Automations',
        route: '/automations',
        icon: 'large-automations',
        current: false,
    },
    {
        name: 'News',
        route: '/news',
        icon: 'large-tv',
        current: false,
    },
    {
        name: 'Settings',
        route: '/settings',
        icon: 'large-ruler',
        current: false,
    },
];

const devNav = [
    {
        name: 'Development',
        route: '/development',
        icon: 'development',
        current: false,
    },
];

const adminNav = [
    { name: 'Leads', route: '/orders', icon: 'large-Magnet', current: false },
    {
        name: 'Jobs',
        route: '/jobs',
        icon: 'large-Box-stack',
        current: false,
        subitems: [
            { name: 'Move jobs', route: '/jobs', icon: 'large-Box-stack', current: false },
            { name: 'Ancillary only', route: '/ancillary', icon: 'large-ancillary', current: false },
        ],
    },
    {
        name: 'Finance',
        route: '/finance',
        icon: 'large-Cash',
        current: false,
        subitems: [
            {
                name: 'Transactions',
                route: '/finance',
                icon: 'large-Cash',
                current: false,
            },
            { name: 'Payouts', route: '/payouts', icon: 'payouts', current: false },
            { name: 'Coupons', route: '/coupons', icon: 'coupons', current: false },
        ],
    },
    {
        name: 'Companies',
        route: '/companies',
        icon: 'large-Truck-front',
        current: false,
    },
    {
        name: 'Interstate',
        route: '/interstate',
        icon: 'large-Map',
        current: false,
    },
    { name: 'Depots', route: '/depots', icon: 'large-Shed', current: false },
    { name: 'Local', route: '/local', icon: 'large-Pin', current: false },
    { name: 'Fleet', route: '/fleet', icon: 'large-Truck-side', current: false },
    { name: 'Staff', route: '/staff', icon: 'large-Movers', current: false },
    {
        name: 'Affiliates',
        route: '/affiliates',
        icon: 'large-group-of-people',
        current: false,
    },
    {
        name: 'Providers',
        route: '/providers',
        icon: 'large-Movers',
        current: false,
    },
    {
        name: 'Communication',
        route: '/communication',
        icon: 'large-Chat',
        current: false,
    },
    {
        name: 'Automations',
        route: '/automations',
        icon: 'large-automations',
        current: false,
    },
    {
        name: 'News',
        route: '/news',
        icon: 'large-tv',
        current: false,
    },
    {
        name: 'Settings',
        route: '/settings',
        icon: 'large-ruler',
        current: false,
    },
];

const companyNav = [
    { name: 'Leads', route: '/orders', icon: 'large-Magnet', current: false },
    {
        name: 'Jobs',
        route: '/jobs',
        icon: 'large-Box-stack',
        current: false,
        subitems: [
            { name: 'Move jobs', route: '/jobs', icon: 'large-Box-stack', current: false },
            { name: 'Ancillary only', route: '/ancillary', icon: 'large-ancillary', current: false },
        ],
    },
    {
        name: 'Companies',
        route: '/companies',
        icon: 'large-Truck-front',
        current: false,
    },
    {
        name: 'Finance',
        route: '/finance',
        icon: 'large-Cash',
        current: false,
        subitems: [{ name: 'Payouts', route: '/payouts', icon: 'payouts', current: false }],
    },
    {
        name: 'Interstate',
        route: '/interstate',
        icon: 'large-Map',
        current: false,
    },
    { name: 'Depots', route: '/depots', icon: 'large-Shed', current: false },
    { name: 'Local', route: '/local', icon: 'large-Pin', current: false },

    { name: 'Fleet', route: '/fleet', icon: 'large-Truck-side', current: false },
    { name: 'Staff', route: '/staff', icon: 'large-Movers', current: false },
    {
        name: 'Providers',
        route: '/providers',
        icon: 'large-Movers',
        current: false,
    },
    {
        name: 'News',
        route: '/news',
        icon: 'large-tv',
        current: false,
    },
];

const agentNav = [
    { name: 'Leads', route: '/orders', icon: 'large-Magnet', current: false },
    {
        name: 'Jobs',
        route: '/jobs',
        icon: 'large-Box-stack',
        current: false,
        subitems: [
            { name: 'Move jobs', route: '/jobs', icon: 'large-Box-stack', current: false },
            { name: 'Ancillary only', route: '/ancillary', icon: 'large-ancillary', current: false },
        ],
    },
    {
        name: 'Companies',
        route: '/companies',
        icon: 'large-Truck-front',
        current: false,
    },
    {
        name: 'Interstate',
        route: '/interstate',
        icon: 'large-Map',
        current: false,
    },
    { name: 'Depots', route: '/depots', icon: 'large-Shed', current: false },
    { name: 'Local', route: '/local', icon: 'large-Pin', current: false },
    { name: 'Fleet', route: '/fleet', icon: 'large-Truck-side', current: false },
    {
        name: 'Providers',
        route: '/providers',
        icon: 'large-Movers',
        current: false,
    },
];

const financeNav = [
    {
        name: 'Finance',
        route: '/finance',
        icon: 'large-Cash',
        current: false,
        subitems: [
            {
                name: 'Transactions',
                route: '/finance',
                icon: 'large-Cash',
                current: false,
            },
            { name: 'Payouts', route: '/payouts', icon: 'payouts', current: false },
        ],
    },
];

const manager = [
    { name: 'Quotes', route: '/quotes', icon: 'large-Magnet', current: false },
    { name: 'Jobs', route: '/jobs', icon: 'large-Box-stack', current: false },
    { name: 'Depots', route: '/depots', icon: 'large-Shed', current: false },
    { name: 'Local', route: '/local', icon: 'large-Pin', current: false },
    {
        name: 'Interstate',
        route: '/interstate',
        icon: 'large-Map',
        current: false,
    },
    { name: 'Finance', route: '/finance', icon: 'large-Cash', current: false },
    { name: 'Fleet', route: '/fleet', icon: 'large-Truck-side', current: false },
    { name: 'Staff', route: '/staff', icon: 'large-Movers', current: false },
    { name: 'Scheduler', route: '/scheduler', icon: 'scheduler', current: false },
];

function sidebarItems(roles, status) {
    let navigationItems = [
        {
            name: 'Dashboard',
            route: '/dashboard',
            icon: 'large-Dial',
            current: false,
        },
    ];
    if (status != 'PENDING' && status != 'ONBOARDED') {
        roles.forEach((role) => {
            switch (role) {
                case 'MUVAL_AGENT':
                    navigationItems = [...navigationItems, ...agentNav];
                    break;
                case 'MUVAL_TRIAGE':
                    navigationItems = [...navigationItems, ...agentNav];
                    break;
                case 'MUVAL_ADMIN':
                    navigationItems = [...navigationItems, ...adminNav];
                    break;
                case 'SUPER':
                    navigationItems = [...navigationItems, ...superNav];
                    break;
                case 'DEVELOPER':
                    navigationItems = [...navigationItems, ...devNav];
                    break;
                case 'MUVAL_COMPANY':
                    navigationItems = [...navigationItems, ...companyNav];
                    break;
                case 'MUVAL_FINANCE':
                    navigationItems = [...navigationItems, ...financeNav];
                    break;
                case 'COMPANY_MANAGER':
                    navigationItems = [...navigationItems, ...manager];
                    break;
            }
        });
    }

    return uniqBy([...navigationItems], 'name');
}

export default {
    sidebarItems,
};
