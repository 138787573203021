export default [
    {
        path: '/companies',
        name: 'Companies',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/companies/CompaniesIndex.vue'),
            agent: () => import('@views/companies/CompaniesIndex.vue'),
        },
    },
    {
        path: '/company/profile',
        name: 'CompanyProfile',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/company/CompanyProfile.vue'),
            agent: () => import('@views/company/CompanyProfile.vue'),
        },
    },
    {
        path: '/company/settings',
        name: 'CompanySettings',
        meta: {
            requiresAuth: true,
        },
        components: {
            default: () => import('@views/company/CompanySettings.vue'),
            agent: () => import('@views/company/CompanySettings.vue'),
        },
    },
];
