<template>
    <TransitionRoot
        as="template"
        :show="open"
    >
        <Dialog
            as="div"
            :initial-focus="defaultFocus"
            static
            class="fixed inset-0 z-50 mx-auto overflow-y-auto"
            :open="open"
            @close="closeOnOutside ? handleCancel : null"
        >
            <div
                class="mx-auto flex min-h-screen max-w-5xl items-end justify-center text-center sm:block md:px-8 md:pt-4 md:pb-20"
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    v-if="!top"
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="sm:max-w-screen inline-block w-full transform overflow-y-visible rounded-muval-2 rounded-b-none bg-white text-left align-bottom shadow-muval-3 transition-all dark:bg-gray-800 sm:my-8 sm:align-middle md:rounded-b-muval-2"
                        :class="
                            modalSize ? modalSize
                            : isComponent == 'DeleteModal' ? 'md:w-2/3'
                            : 'md:w-full'
                        "
                    >
                        <div class="px-4 pt-5 pb-4 dark:bg-gray-700 sm:p-6">
                            <div class="mb-2 flex h-12">
                                <h3
                                    v-show="hasHeaderSlot"
                                    class="dark:border-gray-600"
                                >
                                    <slot name="header"></slot>
                                </h3>
                                <DialogTitle
                                    v-if="title"
                                    as="h3"
                                    class="mb-4 text-lg font-medium"
                                >
                                    {{ title }}
                                </DialogTitle>
                                <DialogTitle
                                    v-if="isComponent == 'DeleteModal'"
                                    as="span"
                                    class="flex items-center gap-2.5 text-base font-medium"
                                >
                                    <SvgIcon
                                        name="system-error"
                                        class="h-6 w-6 text-error"
                                    />
                                    Are you sure?
                                </DialogTitle>
                                <button
                                    id="closeButton"
                                    type="button"
                                    class="ml-auto flex h-8 w-8 items-center justify-center rounded-full bg-white hover:bg-gray-100 focus:outline-none dark:bg-gray-800"
                                    @click="handleCancel"
                                >
                                    <XIcon class="h-5 w-5 text-gray-900 dark:text-white"></XIcon>
                                </button>
                            </div>
                            <div
                                class="font-body dark:text-white"
                                :class="{
                                    'pb-8 md:pb-0': hasFooterSlot,
                                }"
                            >
                                <Suspense>
                                    <div>
                                        <component
                                            v-bind="{
                                                data: data,
                                                url: url,
                                                parentForm: parentForm,
                                                itemId: itemId,
                                                itemIndex: itemIndex,
                                                itemType: itemType,
                                                deleteItemSchema: deleteItemSchema,
                                                isCreate: isCreate,
                                                isAdd: isAdd,
                                                emitKey: emitKey,
                                            }"
                                            :is="isComponent"
                                            v-if="isComponent"
                                            @remove:item="removeItem"
                                            @close:modal="closeModal"
                                            @cancel:modal="emit('update:modelValue', false)"
                                        />
                                        <slot
                                            v-else
                                            @close:modal="closeModal"
                                        />
                                    </div>
                                    <template #fallback>
                                        <div class="flex flex-col space-y-4">
                                            <div
                                                v-for="idx in 5"
                                                :key="idx"
                                                class="h-12 w-full bg-muval-gray-5"
                                            ></div>
                                        </div>
                                    </template>
                                </Suspense>
                            </div>
                        </div>
                        <div
                            v-show="hasFooterSlot"
                            class="border-t bg-gray-50 px-4 py-3 dark:border-gray-600 dark:bg-gray-800 dark:text-white sm:px-6"
                        >
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script>
import { XIcon } from '@heroicons/vue/solid';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

// Modal Templates
import AddInterstatePrice from './templates/AddInterstatePrice.vue';
import EditInterstatePrice from './templates/EditInterstatePrice.vue';
import DeleteModal from '@components/modal/templates/DeleteModal.vue';
import RestoreModal from '@components/modal/templates/RestoreModal.vue';
import EmailModal from '@components/modal/templates/EmailModal.vue';
import SmsModal from '@components/modal/templates/SmsModal.vue';
import ArchiveModal from '@components/modal/templates/ArchiveModal.vue';
import AssignAgent from '@components/modal/templates/AssignAgent.vue';

export default {
    components: {
        XIcon,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,

        // Modals
        AddInterstatePrice,
        EditInterstatePrice,
        DeleteModal,
        RestoreModal,
        EmailModal,
        SmsModal,
        ArchiveModal,
        AssignAgent,
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        closeButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        closeOnOutside: {
            type: Boolean,
            required: false,
            default: true,
        },
        focusRef: {
            type: [Object, null],
            required: false,
            default: null,
        },
        top: {
            type: Boolean,
            default: false,
        },
        isComponent: {
            type: [Object, null],
            required: false,
            default: null,
        },
        data: {
            type: [Object, null],
            required: false,
            default: null,
        },

        // Which url to use for the component
        url: {
            type: [String, null],
            required: false,
            default: null,
        },

        // Gives modal or it's dynamic component access to passed form useForm()
        parentForm: {
            type: [Function, null],
            required: false,
            default: null,
        },

        // Used to identify if the context is being created
        isCreate: {
            type: Boolean,
            required: false,
            default: false,
        },

        // Used to identify whether the edit should be in an "Add" view
        isAdd: {
            type: Boolean,
            required: false,
            default: false,
        },

        // Used to identify the item being edited or deleted
        itemId: {
            type: [Number, null],
            required: false,
            default: null,
        },
        itemIndex: {
            type: [Number, null],
            required: false,
            default: null,
        },
        itemType: {
            type: [String, null],
            required: false,
            default: null,
        },

        // Schema used to customize what delete reference data is shown in the DeleteModal
        deleteItemSchema: {
            type: [Array, null],
            required: false,
            default: null,
        },
        emitKey: {
            type: [String, null],
            required: false,
            default: null,
        },
        modalSize: {
            type: [String, null],
            required: false,
            default: null,
        },
    },
    emits: ['update:modelValue', 'remove:item', 'success', 'modal:cancel'],
    setup(props, { emit, slots }) {
        const defaultFocus = ref(null);

        const hasHeaderSlot = computed(() => {
            return !!slots.header;
        });

        function closeModal() {
            emit('update:modelValue', false);
            emit('success');
        }

        function removeItem(event) {
            emit('remove:item', event);
        }

        function handleCancel() {
            emit('modal:cancel');
            emit('update:modelValue', false);
        }

        return {
            hasHeaderSlot,
            closeModal,
            handleCancel,
            removeItem,
            defaultFocus,
            emit,
        };
    },
    computed: {
        open: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },

        hasFooterSlot() {
            return !!this.$slots.footer;
        },
    },
};
</script>
