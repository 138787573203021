<template>
    <SvgIcon
        name="system-search"
        class="box-content h-6 w-6 shrink-0 cursor-pointer rounded-full p-2 text-muval-gray-1 transition-all hover:bg-muval-gray-5"
        @click="modalCtx.open"
    />
    <TransitionRoot
        appear
        :show="isOpen"
        as="template"
    >
        <Dialog
            as="div"
            class="relative z-10"
            @close="modalCtx.close"
        >
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-start justify-center p-4 pt-32 text-center md:items-center md:pt-4">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full max-w-md transform overflow-hidden rounded-muval-2 bg-white p-2 text-left align-middle shadow-muval-2 transition-all"
                        >
                            <div class="relative flex">
                                <input
                                    v-model="searchInput"
                                    class="peer ml-8 mr-16 h-12 w-full rounded-muval-1 text-lg placeholder:text-muval-gray-3 focus:outline-none focus:ring-0"
                                    placeholder="Search"
                                    @keydown.enter.stop="gotoSearch(searchInput)"
                                />
                                <SvgIcon
                                    name="system-search"
                                    class="absolute top-1/2 left-0.5 h-6 w-6 -translate-y-1/2 text-muval-gray-2"
                                    placeholder="Search"
                                />
                                <div
                                    class="right-2 top-1/2 hidden -translate-y-1/2 rounded-muval-1 bg-muval-gray-4 py-0.5 px-1 text-xs font-medium text-muval-gray-2 md:absolute md:block"
                                >
                                    CTRL+K
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
const userStore = useUserStore();
const router = useRouter();
const keys = useMagicKeys({
    passive: false,
    onEventFired(e) {
        if (e.key === 'k' && (e.ctrlKey || e.metaKey)) e.preventDefault();
    },
});
const ctrlK = keys['Ctrl+K'];
const cmdK = keys['Cmd+K'];

const isOpen = ref(false);
const searchInput = ref();

const modalCtx = {
    open: () => (isOpen.value = true),
    close: () => {
        isOpen.value = false;
        searchInput.value = '';
    },
    toggle: () => {
        isOpen.value = !isOpen.value;
        searchInput.value = '';
    },
};

whenever(ctrlK, modalCtx.toggle);
whenever(cmdK, modalCtx.toggle);

const gotoSearch = (value) => {
    userStore.search = value;
    router.push({ name: 'Search' });
    modalCtx.close();
};
</script>
