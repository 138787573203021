<template>
    <component
        :is="tooltip ? MuvalTippy : 'div'"
        v-bind="{ interactive: false }"
        :class="{ 'w-fit': !tooltip, 'pointer-events-none': disabled }"
    >
        <component
            :is="
                href ? 'a'
                : to ? 'router-link'
                : 'button'
            "
            :id="id"
            :data-cy="dataCy"
            class="group relative flex w-fit flex-shrink-0 cursor-pointer appearance-none items-center justify-center rounded-full text-sm transition-all"
            :type="$props.type"
            :class="[$attrs?.class, getStyles(), getSize(), loading ? 'pointer-events-none cursor-wait' : null]"
            :href="href"
            :to="to"
            :target="href ? target : null"
        >
            <div
                class="flex h-full w-full items-center justify-center"
                :class="{
                    'flex-row-reverse': iconRight,
                    invisible: loading,
                    'gap-1.5': slots.default,
                }"
            >
                <SvgIcon
                    v-if="icon"
                    :name="icon"
                    class="h-5 w-5 overflow-visible"
                    :class="{
                        'transition-all duration-75': iconAnimate,
                        'group-hover:translate-x-1': iconAnimate && iconRight && !iconAnimation,
                        'group-hover:-translate-x-1': iconAnimate && !iconRight && !iconAnimation,
                        [iconAnimation]: iconAnimate,
                    }"
                />
                <span class="whitespace-nowrap"><slot></slot></span>
            </div>
            <div
                v-if="loading"
                class="absolute inset-0 flex h-full w-full"
            >
                <SvgIcon
                    name="spinner"
                    class="m-auto h-5 w-5 animate-spin"
                />
            </div>
        </component>
        <template
            v-if="tooltip"
            #content
        >
            <div class="text-sm">
                {{ tooltip }}
            </div>
        </template>
    </component>
</template>

<script setup>
import MuvalTippy from '@components/MuvalTippy.vue';

const props = defineProps({
    id: {
        type: String,
        default: null,
    },
    primary: {
        type: Boolean,
        required: false,
        default: false,
    },
    secondary: {
        type: Boolean,
        required: false,
        default: false,
    },
    tertiary: {
        type: Boolean,
        required: false,
        default: false,
    },
    quaternary: {
        type: Boolean,
        required: false,
        default: false,
    },
    alternate: {
        type: Boolean,
        required: false,
        default: false,
    },
    link: {
        type: Boolean,
        required: false,
        default: false,
    },
    small: {
        type: Boolean,
        required: false,
        default: false,
    },
    large: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    iconRight: {
        type: Boolean,
        required: false,
        default: false,
    },

    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    icon: {
        type: [String, null],
        required: false,
        default: null,
    },
    iconAnimate: {
        type: Boolean,
        required: false,
        default: false,
    },
    iconAnimation: {
        type: String,
        required: false,
        default: '',
    },
    tooltip: {
        type: String,
        required: false,
        default: '',
    },
    href: {
        type: [String, null],
        required: false,
        default: null,
    },
    target: {
        type: String,
        required: false,
        default: '_blank',
    },
    to: {
        type: [String, Object, null],
        required: false,
        default: null,
    },
    circle: {
        type: Boolean,
        required: false,
        default: false,
    },
    dataCy: {
        type: String,
        required: false,
        default: '',
    },
    type: {
        type: String,
        required: false,
        default: 'submit',
    },
});

const slots = useSlots();

function getStyles() {
    let styles = '';
    if (props.disabled) {
        styles = 'bg-muval-gray-5 text-muval-gray-3 border border-transparent pointer-events-none';
    } else if (props.link) {
        styles =
            'text-muval-sky-1 hover:text-muval-blue-1 underline active:text-muval-blue-1 border border-transparent';
    } else if (props.primary) {
        if (!props.alternate) {
            styles = 'bg-muval-sky-1 text-white hover:bg-muval-blue-1 border border-transparent';
        } else {
            styles = 'bg-brand text-white hover:bg-brand-shade border border-transparent';
        }
    } else if (props.secondary) {
        if (!props.alternate) {
            styles = 'border border-muval-sky-1 text-muval-sky-1 hover:border-muval-blue-1 hover:text-muval-blue-1';
        } else {
            styles = 'border border-brand text-brand hover:border-brand-shade hover:text-brand-shade';
        }
    } else if (props.tertiary) {
        if (!props.alternate) {
            styles = 'border border-muval-gray-4 text-muval-gray-1 bg-white hover:text-muval-sky-1';
        } else {
            styles = 'border border-muval-gray-4 text-muval-gray-1 bg-white hover:text-brand';
        }
    } else if (props.quaternary) {
        if (!props.alternate) {
            styles = 'text-muval-sky-1 hover:bg-muval-gray-5 hover:text-muval-blue-1 border border-transparent';
        } else {
            styles = 'text-brand hover:bg-muval-gray-5 hover:text-brand-shade border border-transparent';
        }
    } else {
        if (!props.alternate) {
            styles = 'bg-muval-sky-1 text-white hover:bg-muval-blue-1 border border-transparent';
        } else {
            styles = 'bg-brand text-white hover:bg-brand-shade border border-transparent';
        }
    }

    return styles;
}

function getSize() {
    if (props.circle) {
        return 'h-input px-2';
    }

    if (props.small) {
        return 'h-input-small px-3';
    } else {
        return 'h-input px-4';
    }
}
</script>
