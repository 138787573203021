<template>
    <div
        class="relative flex min-w-input"
        :class="[small ? 'h-7' : 'h-input', { 'pointer-events-none': disabled }]"
    >
        <div
            class="absolute -bottom-5 w-full truncate whitespace-nowrap text-xxs"
            :class="errorMessage ? 'text-error' : 'hidden'"
        >
            <span class="text-xxs">{{ errorMessage }}</span>
        </div>
        <MultiSelect
            ref="multiSelect"
            v-model="inputValue"
            :data-cy="vid"
            :groups="grouped"
            :placeholder="placeholder ? placeholder : label"
            :class="{
                'border-error': errorMessage,
                'border-muval-gray-4': !errorMessage,
            }"
            :classes="{
                container: `peer ${
                    small ? 'h-7' : 'h-input'
                } relative bg-white dark:bg-gray-900 mx-auto flex items-center w-full justify-end cursor-pointer border  hover:border-muval-gray-3 focus:border-focus text-sm text-muval-gray-1 transition leading-snug outline-none ${
                    rounded ? 'rounded-full pl-2' : 'rounded-input'
                } ${inputValue && (!Array.isArray(inputValue) || inputValue.length) && highlightOnValue ? '!border-focus' : ''}`,
                containerDisabled: 'cursor-default bg-muval-gray-5',
                containerOpen: `${rounded ? 'rounded-full' : 'rounded-input'}`,
                containerOpenTop: `${rounded ? 'rounded-full' : 'rounded-input'}`,
                containerActive: 'border border-focus',
                singleLabel:
                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                multipleLabel:
                    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                search: `w-full absolute ${
                    small ? 'h-6' : 'h-[2.2rem]'
                } focus:ring-0 inset-0 outline-none appearance-none bg-transparent border-1 border-gray-300 text-sm font-sans dark:bg-gray-900 -pb-4 ${
                    rounded ? 'rounded-full' : 'rounded-input'
                } pl-4 outline-0 focus:outline-0 border-0`,
                tags: 'flex-grow items-center flex-shrink flex truncate items-center mt-1 pl-2',
                tag: 'bg-brand text-white text-sm font-semibold pl-2 rounded  flex items-center whitespace-nowrap',
                tagDisabled: 'pr-2 !bg-gray-400 text-white',
                tagRemove:
                    'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                tagRemoveIcon:
                    'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                tagsSearchWrapper: 'inline-block relative mx-1 flex-grow flex-shrink h-full',
                tagsSearch:
                    'absolute focus:ring-0 inset-0 outline-0 appearance-none p-0 text-sm bg-transparent font-sans box-border w-full border-0 focus:border-0 focus:outline-0',
                tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                placeholder: `flex text-gray-400 truncate items-center h-full absolute left-4 placeholder-transparent top-1/2 -translate-y-1/2 pointer-events-none bg-transparent leading-snug `,
                caret: 'text-muval-gray-1 bg-no-repeat w-2.5 h-2 box-content mr-3.5 relative opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform',
                caretOpen: 'rotate-180',
                clear: 'text-white pr-3.5 relative z-10 text-white opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                clearIcon: 'text-white  bg-center bg-no-repeat w-2.5 box-content inline-block',
                spinner:
                    'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
                dropdown:
                    'absolute -left-px -right-px -bottom-1 transform translate-y-full shadow-md -mt-px overflow-y-auto max-h-[250px] z-50 bg-white dark:bg-gray-900 flex flex-col rounded-md',
                dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
                dropdownHidden: 'hidden',
                groupLabel:
                    'flex text-xs box-border text-muval-gray-2 -ml-1 items-center justify-start text-left py-2 px-3 cursor-default leading-normal',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: 'flex-col-reverse',
                option: 'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3',
                optionPointed: 'text-gray-800 bg-muval-gray-6',
                optionSelected: 'text-muval-gray-1 bg-muval-gray-5',
                optionDisabled: 'text-gray-300 cursor-not-allowed',
                optionSelectedPointed: 'text-muval-gray-1 bg-muval-gray-5 bg-opacity-70',
                optionSelectedDisabled: 'text-orange-100 bg-orange-500 bg-opacity-50 cursor-not-allowed',
                noOptions: 'px-3 text-gray-600 dark:bg-gray-900',
                noResults: 'px-3 py-2 text-gray-600 dark:bg-gray-900',
                fakeInput:
                    'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent border',
                spacer: 'h-[1rem]',
            }"
            :caret="true"
            :mode="mode"
            :disabled="disabled"
            :can-clear="!cannotClear"
            :searchable="canSearch"
            :create-tag="false"
            :max="limit"
            :track-by="searchable ? searchable : displayLabel"
            :options="selectOptions"
            :value-prop="valueProp"
            :label="displayLabel"
            :can-deselect="!cannotDeselect"
            :loading="loading"
            @change="(value) => emit('change', value)"
            @keydown.space.stop
        >
            <template #caret>
                <svg
                    v-if="!loading"
                    class="mr-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @mousedown.prevent.stop="multiSelect.isOpen = !multiSelect.isOpen"
                >
                    <path
                        d="M15.9625 9.2925L12.0825 13.1725L8.2025 9.2925C7.8125 8.9025 7.1825 8.9025 6.7925 9.2925C6.4025 9.6825 6.4025 10.3125 6.7925 10.7025L11.3825 15.2925C11.7725 15.6825 12.4025 15.6825 12.7925 15.2925L17.3825 10.7025C17.7725 10.3125 17.7725 9.6825 17.3825 9.2925C16.9925 8.9125 16.3525 8.9025 15.9625 9.2925Z"
                        fill="#393939"
                    />
                </svg>
                <template v-else>
                    <SvgIcon
                        name="spinner"
                        class="z-10 mr-4 h-3 w-3 animate-spin"
                    />
                </template>
            </template>
            <template #clear>
                <button @click.prevent.stop="clear">
                    <svg
                        class="mr-2 h-4 w-4 text-error dark:text-red-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </button>
            </template>

            <template #tag="{ option, handleTagRemove }">
                <slot
                    name="tag"
                    v-bind="{ option, handleTagRemove }"
                >
                    <div
                        class="mr-1 mb-0.5 flex items-center whitespace-nowrap rounded bg-muval-gray-5 pl-2 text-sm font-medium text-muval-gray-1 dark:bg-blue-900"
                    >
                        {{ option.label }}
                        <div
                            class="group mx-0.5 flex items-center justify-center rounded-sm p-0.5"
                            @click.prevent
                            @mousedown.prevent.stop="handleTagRemove(option, $event)"
                        >
                            <svg
                                class="h-4 w-4 rounded-sm hover:bg-muval-gray-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </slot>
            </template>

            <template #singlelabel="{ value }">
                <template v-if="!displayComponent">
                    <div class="absolute flex h-full w-full">
                        <div class="my-auto ml-4 flex w-3/4 flex-nowrap whitespace-nowrap">
                            <slot
                                name="selected"
                                v-bind="{
                                    selected: value,
                                }"
                            >
                                <p class="truncate text-sm font-normal">
                                    {{ value[displayLabel] }}
                                </p>
                            </slot>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="z-0 flex h-full w-full">
                        <slot
                            name="selected"
                            v-bind="{ value }"
                        >
                            <component
                                :is="displayComponent"
                                :key="value"
                                :data="value"
                                :config="config"
                            />
                        </slot>
                    </div>
                </template>
            </template>

            <template #option="{ option, isSelected, isPointed }">
                <template v-if="!optionComponent">
                    <slot
                        name="option"
                        v-bind="{
                            option,
                            isSelected: isSelected(option),
                            isPointed: isPointed(option),
                        }"
                    >
                        <div>{{ option[displayLabel] }}</div>
                    </slot>
                </template>
                <template v-else>
                    <div class="z-0 w-full">
                        <slot
                            name="option"
                            v-bind="{
                                option,
                                isSelected: isSelected(option),
                                isPointed: isPointed(option),
                            }"
                        >
                            <component
                                :is="optionComponent"
                                :data="option"
                                :config="config"
                                :selected="isSelected(option)"
                                :pointed="isPointed(option)"
                            />
                        </slot>
                    </div>
                </template>
            </template>

            <template #nooptions>
                <template v-if="optionComponent === 'MSVehicle'">
                    <div class="container flex flex-col items-center gap-1 p-4">
                        <span class="font-medium">No vehicles found!</span>
                        <MuvalButton to="/fleet/create">Create a new vehicle.</MuvalButton>
                    </div>
                </template>
                <template v-if="placeholder === 'Depot'">
                    <div class="flex flex-col py-5 px-5">
                        <span class="mx-auto font-medium">No depots found!</span>
                        <router-link
                            class="btn"
                            to="/depots/create"
                        >
                            Create a new depot.
                        </router-link>
                    </div>
                </template>
                <template v-else>
                    <div class="px-3 py-2">The list is empty</div>
                </template>
            </template>
        </MultiSelect>
        <div
            v-if="label && (multiSelect !== null ? Object.keys(multiSelect?.iv).length : false)"
            :class="`z-5 pointer-events-none absolute left-2 -top-2 text-xs text-muval-gray-3 transition-all duration-75 line-clamp-1 peer-placeholder-shown:top-2 peer-placeholder-shown:text-xs peer-placeholder-shown:text-muval-gray-3 peer-focus:-top-2 peer-focus:text-xs peer-focus:text-muval-sky-1`"
        >
            <span class="relative z-10 px-2">{{ label }}</span>
            <div class="absolute top-2 left-1.5 h-px w-full bg-white dark:bg-gray-900"></div>
        </div>
        <div
            v-if="loading && !small"
            class="absolute top-0 my-auto flex h-full w-full"
        >
            <div
                class="m-auto h-full w-full animate-pulse bg-muval-gray-1/20"
                :class="rounded ? 'rounded-full' : 'rounded-input'"
            ></div>
        </div>

        <div
            :class="{
                'h-7': small,
                'h-input': !small,
                'rounded-full': rounded,
                'rounded-input': !rounded,
            }"
            class="skeleton:loader"
        ></div>
    </div>
</template>

<script setup>
import { useField } from 'vee-validate';
import MultiSelect from '@vueform/multiselect';
import MuvalButton from '@buttons/MuvalButton.vue';
import { idGen } from '@utilities/idGen';

const props = defineProps({
    modelValue: {
        type: [Array, Object, String, null],
        required: false,
        default: null,
    },
    label: {
        type: String,
        required: false,
        default: null,
    },
    placeholder: {
        type: String,
        required: false,
        default: 'Select an option',
    },
    selectOptions: {
        type: [Array, Object],
        required: true,
    },
    config: {
        type: Object,
        required: false,
        default: null,
    },
    vid: {
        type: String,
        required: false,
        default: () => idGen(6),
    },
    limit: {
        type: Number,
        required: false,
        default: 10,
    },
    displayLabel: {
        type: String,
        required: false,
        default: 'name',
    },
    valueProp: {
        type: String,
        required: false,
        default: 'id',
    },
    rounded: {
        type: Boolean,
        default: false,
    },
    small: {
        type: Boolean,
        default: false,
    },
    canSearch: {
        type: Boolean,
        required: false,
        default: true,
    },
    searchable: {
        type: String,
        required: false,
        default: '',
    },
    displayComponent: {
        type: [String, null],
        required: false,
        default: null,
    },
    optionComponent: {
        type: [String, null],
        required: false,
        default: null,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    grouped: {
        type: Boolean,
        required: false,
        default: false,
    },
    cannotClear: {
        type: Boolean,
        required: false,
        default: true,
    },
    cannotDeselect: {
        type: Boolean,
        required: false,
        default: false,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    highlightOnValue: {
        type: Boolean,
        required: false,
        default: false,
    },
    mode: {
        type: String,
        required: false,
        default: 'single',
        validator: (value) => {
            return ['single', 'multiple', 'tags'].includes(value);
        },
    },
});

const emit = defineEmits(['update:modelValue', 'change']);

const multiSelect = ref(null);
function clear() {
    multiSelect.value.clear();
}

const { value: inputValue, errorMessage } = useField(toRef(props, 'vid'), undefined, {
    initialValue: props.modelValue,
});

defineExpose({ clear: clear });
</script>
<script>
// MSComponents
/*eslint-disable */
import MSDefault from '@components/inputs/multiselect/options/MSDefault.vue';
import MSDefaultNoCheckbox from '@components/inputs/multiselect/options/MSDefaultNoCheckbox.vue';
import MSService from '@components/inputs/multiselect/options/MSService.vue';
import MSVehicle from '@components/inputs/multiselect/options/MSVehicle.vue';
import MSPriceItem from '@components/inputs/multiselect/options/MSPriceItem.vue';
import MSAgent from '@components/inputs/multiselect/options/MSAgent.vue';
import MSPublished from '@components/inputs/multiselect/options/MSPublished.vue';
import MSCompany from '@components/inputs/multiselect/options/MSCompany.vue';
import MSChart from '@inputs/multiselect/options/MSChart.vue';
import MSLozenge from '@inputs/multiselect/options/MSLozenge.vue';
import MSRefundPaymentOption from '@inputs/multiselect/options/MSRefundPaymentOption.vue';
import MSRefundPayment from '@inputs/multiselect/options/MSRefundPayment.vue';
/*eslint-enable */
export default {
    components: {
        MSDefault,
        MSDefaultNoCheckbox,
        MSService,
        MSVehicle,
        MSPriceItem,
        MSAgent,
        MSCompany,
        MSPublished,
        MSChart,
        MSLozenge,
        MSRefundPaymentOption,
        MSRefundPayment,
    },
};
</script>
