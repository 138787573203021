<template>
    <Switch
        :id="vid"
        v-model="inputValue"
        :class="[
            inputValue ?
                redVariant ? 'bg-muval-red-1 hover:bg-muval-red-1'
                :   'bg-muval-sky-1 hover:bg-muval-blue-1'
            :   'bg-muval-gray-4 hover:bg-muval-gray-3 dark:bg-muval-gray-4',
            'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer overflow-hidden rounded-full px-0.5 transition-colors duration-200 ease-in-out',
            hasError ? 'bg-red-200 focus:ring-red-600' : 'bg-gray-200',
            disabled ? 'pointer-events-none grayscale' : '',
        ]"
        @update:modelValue="
            (e) => {
                onInput(e);
            }
        "
    >
        <span
            aria-hidden="true"
            :class="[
                inputValue ? 'translate-x-[125%]' : 'translate-x-0',
                'pointer-events-none my-auto inline-block h-4 w-4 transform rounded-full bg-white shadow-muval-2 ring-0 transition duration-200 ease-in-out',
                hasError ? 'bg-error' : '',
            ]"
        >
            <template v-if="!loading && hasError">
                <svg
                    class="h-4 w-4 text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </template>
            <template v-if="loading">
                <SvgIcon
                    name="spinner"
                    class="h-full w-full animate-spin p-0.5 text-muval-gray-3"
                />
            </template>
        </span>
        <p
            v-if="errorMessage"
            class="absolute -bottom-3.5 text-xs font-normal"
            :class="errorMessage ? 'text-error' : ''"
        >
            {{ errorMessage }}
        </p>
    </Switch>
</template>
<script setup>
import { Switch } from '@headlessui/vue';
import { useField } from 'vee-validate';
import { idGen } from '../../utilities/idGen';

const props = defineProps({
    id: {
        type: [String, Number],
        required: true,
    },
    modelValue: {
        type: Boolean,
        required: true,
    },
    hasError: {
        type: Boolean,
        required: false,
        default: false,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    vid: {
        type: String,
        required: false,
        default: () => idGen(6),
    },
    redVariant: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const {
    value: inputValue,
    errorMessage,
    handleChange,
} = useField(props.vid || '', undefined, {
    initialValue: props.modelValue,
    valueProp: props.modelValue,
    validateOnValueUpdate: false,
});

const onInput = (event) => {
    handleChange(event, true);
};
</script>
