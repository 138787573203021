<template>
    <!-- Version Toast -->
    <notifications
        v-if="newRelease && !showReleaseModal"
        :pause-on-hover="true"
        :max="1"
        width="340"
        group="app_release"
        position="bottom center w-full"
        class="!bottom-[30px]"
        :duration="-1"
        :speed="1000"
    >
        <template #body="{ item }">
            <div
                class="relative m-4 flex h-fit cursor-pointer rounded-muval-2 border-gray-300 bg-white px-3 py-3 pb-4 shadow-muval-3 shadow-muval-focus"
                @click="toggleModal()"
            >
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        class="h-6 w-6 text-muval-sky-1"
                        fill="currentColor"
                    >
                        <path
                            d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"
                        />
                    </svg>
                </div>
                <div class="my-auto ml-2 flex w-full justify-between">
                    <div class="flex flex-col text-sm">
                        <span class="font-bold">{{ item.title }}</span>
                        <span class="text-xs">
                            {{ item.text }}
                            <span class="font-mono text-xxs opacity-50">{{ item.data?.release }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </notifications>

    <MuvalModal
        v-if="newRelease"
        v-model="showReleaseModal"
        modalSize="md:w-2/3"
    >
        <template #header>
            <div class="flex">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    class="mr-2 h-6 w-6 text-muval-sky-1"
                    fill="currentColor"
                >
                    <path
                        d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"
                    />
                </svg>
                New release available
            </div>
        </template>
        <div>
            <div class="-mt-2 mb-4 font-body text-sm">
                Please save any work in progress and then refresh for the latest features and bug fixes. We recommend
                you perform a force-refresh to be sure you're on the latest version.
            </div>
            <div class="-mt-2 mb-4 font-body text-sm">
                Use Shift (⇧)-F5 on Windows or Cmd (⌘)-Shift (⇧)-R on macOS to force-refresh.
            </div>

            <div class="flex gap-4">
                <MuvalButton @click="refresh()">Refresh</MuvalButton>

                <MuvalButton
                    secondary
                    @click="toggleDetails()"
                >
                    {{ showReleaseDetails ? 'Hide' : 'View' }} details
                </MuvalButton>
            </div>

            <template v-if="showReleaseDetails">
                <ReleaseDetails />
            </template>
        </div>
    </MuvalModal>
</template>

<script setup>
import MuvalModal from '@components/modal/MuvalModal.vue';
import { useAppStore } from '@store/app';

const app = useAppStore();
const showReleaseModal = ref(false);
const showReleaseDetails = ref(false);

const newRelease = computed(() => {
    return app.newRelease;
});

function refresh() {
    window.location.reload(true);
}

function toggleModal() {
    showReleaseModal.value = !showReleaseModal.value;
    showReleaseDetails.value = false;
}

function toggleDetails() {
    showReleaseDetails.value = !showReleaseDetails.value;
}
</script>
