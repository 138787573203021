<template>
    <TransitionRoot
        as="div"
        :show="open"
    >
        <Dialog
            as="div"
            static
            class="fixed inset-0 z-40 flex"
            :open="open"
            @close="app.setSide(false)"
        >
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>
            <TransitionChild
                as="template"
                enter="transition ease-in-out duration-300 transform"
                enter-from="-translate-x-full"
                enter-to="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leave-from="translate-x-0"
                leave-to="-translate-x-full"
            >
                <div class="relative flex w-fit flex-1 flex-col bg-app pt-5 pb-4 dark:bg-gray-900">
                    <TransitionChild
                        as="template"
                        enter="ease-in-out duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in-out duration-300"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <div class="absolute top-5 right-5">
                            <button @click="app.toggleSide()">
                                <span class="sr-only">Close sidebar</span>
                                <SvgIcon
                                    name="system-close"
                                    class="h-6 w-6 text-muval-gray-2"
                                />
                            </button>
                        </div>
                    </TransitionChild>
                    <div class="flex flex-shrink-0 items-center px-8">
                        <router-link to="/dashboard">
                            <SvgIcon
                                v-if="open"
                                class="h-6 w-32 text-gray-900 dark:text-white"
                                name="logo"
                            />
                        </router-link>
                    </div>
                    <div class="mt-5 h-0 flex-1 overflow-y-auto">
                        <nav class="space-y-1">
                            <NavItems />
                        </nav>
                    </div>
                    <MuvalHelp />
                </div>
            </TransitionChild>
            <div
                class="w-14 flex-shrink-0"
                aria-hidden="true"
            >
                <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import MuvalHelp from '@/components/MuvalHelp.vue';

import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';

import { useAppStore } from '@store/app';

const app = useAppStore();
const open = computed({
    get: () => app.side ?? false,
    set: (value) => {
        app.setSide(value);
    },
});
</script>
