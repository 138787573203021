/**
 * Muval CRM API interface
 *
 * @package Muval CRM
 * @module User
 */

const ROUTE = '/affiliates';

async function create(affiliateData) {
    return await window.axios.post(ROUTE, affiliateData);
}

async function retrieve(affiliateId) {
    if (!affiliateId) throw new Error('No affiliate ID provided');
    const {
        data: { data: data },
    } = await window.axios.get(`${ROUTE}/${affiliateId}`);
    return data;
}

async function update(affiliateId, affiliateData) {
    return await window.axios.patch(`${ROUTE}/${affiliateId}`, {
        ...affiliateData,
    });
}

async function destroy(affiliateId) {
    return await window.axios.delete(`${ROUTE}/${affiliateId}`);
}

async function token(affiliateId) {
    return await window.axios.get(`${ROUTE}/${affiliateId}/token/new`);
}

export default {
    create,
    retrieve,
    update,
    destroy,
    token,
};
