<template>
    <div class="flex flex-col gap-1">
        <template
            v-for="(item, idx) in user.navigation"
            :key="idx"
        >
            <component :is="item?.subitems ? Disclosure : 'div'">
                <router-link
                    v-if="!item.subitems"
                    v-slot="{ isActive }"
                    :to="{ path: item.route }"
                    @click.prevent="canRefresh(item.route) && $router.go()"
                    @click.ctrl="modClick(item.route)"
                    @click.meta="modClick(item.route)"
                >
                    <Tippy
                        :follow-cursor="true"
                        :arrow="true"
                        :delay="tipDelay"
                        :duration="[200, 0]"
                    >
                        <!-- TODO: Remove item.name != 'Scheduler' below -->
                        <div
                            v-if="item && item.name != 'Scheduler'"
                            :key="item"
                            class="flex items-center gap-4 overflow-x-clip py-1.5 pl-8 text-base font-normal no-underline hover:bg-muval-gray-6"
                            :class="[
                                {
                                    'bg-muval-gray-6 dark:bg-gray-800': subIsActive(item.route),
                                },
                            ]"
                        >
                            <SvgIcon
                                :class="[
                                    { 'text-brand': subIsActive(item.route) },
                                    { 'text-muval-gray-1 dark:text-white': !isActive },
                                ]"
                                class="h-8 w-8 flex-shrink-0 transition"
                                :name="item.icon"
                            />
                            <span
                                v-if="navExpanded"
                                class="text-muval-gray-1 opacity-100 transition duration-200 ease-in-out dark:text-gray-300"
                                :class="[
                                    { 'text-brand dark:text-brand': subIsActive(item.route) },
                                    { 'text-gray-800:': isActive },
                                ]"
                            >
                                {{ item.name }}
                            </span>
                            <div
                                v-if="navExpanded && item.meta?.count"
                                class="ml-2 rounded-md bg-yellow-100 px-2 py-0.5 text-xs font-bold text-yellow-500"
                            >
                                {{ item.meta?.count }}
                            </div>
                        </div>
                        <template #content>
                            <div class="rounded bg-black bg-opacity-80 px-4 py-1 text-white shadow">
                                {{ item.name }}
                            </div>
                        </template>
                    </Tippy>
                </router-link>
                <template v-else>
                    <DisclosureButton
                        v-slot="{ open }"
                        class="w-full"
                    >
                        <div
                            v-if="item && item.name != 'Scheduler'"
                            :key="item"
                            class="flex items-center gap-4 py-1.5 pl-8 text-base font-normal no-underline hover:bg-muval-gray-6"
                        >
                            <SvgIcon
                                :class="[
                                    {
                                        'text-brand':
                                            open && item.subitems.some((subitem) => subIsActive(subitem.route)),
                                    },
                                    { 'text-muval-gray-1 dark:text-white': !isActive },
                                ]"
                                class="h-8 w-8 flex-shrink-0 transition"
                                :name="item.icon"
                            />
                            <span
                                v-if="navExpanded"
                                class="text-muval-gray-1 opacity-100 transition duration-200 ease-in-out dark:text-gray-300"
                                :class="[
                                    {
                                        'text-brand dark:text-brand':
                                            open && item.subitems.some((subitem) => subIsActive(subitem.route)),
                                    },
                                    { 'text-gray-800:': isActive },
                                ]"
                            >
                                {{ item.name }}
                            </span>
                            <div
                                v-if="navExpanded && item.meta?.count"
                                class="ml-2 rounded-md bg-yellow-100 px-2 py-0.5 text-xs font-bold text-yellow-500"
                            >
                                {{ item.meta?.count }}
                            </div>
                        </div>
                    </DisclosureButton>

                    <DisclosurePanel
                        class="flex flex-col gap-1"
                        as="div"
                    >
                        <router-link
                            v-for="(subitem, subItemIndex) in item.subitems"
                            :key="subItemIndex"
                            v-slot="{ isActive }"
                            :to="{ path: subitem.route }"
                            @click.prevent="canRefresh(subitem.route) && $router.go()"
                            @click.ctrl="modClick(subitem.route)"
                            @click.meta="modClick(subitem.route)"
                        >
                            <div
                                v-if="subitem && subitem.name != 'Scheduler'"
                                :key="subitem"
                                class="flex items-center gap-4 py-1.5 pl-8 text-base font-normal no-underline transition-all hover:bg-muval-gray-6"
                                :class="[
                                    {
                                        'bg-muval-gray-6 dark:bg-gray-800': subIsActive(subitem.route),
                                    },
                                ]"
                            >
                                <div class="flex h-8 w-8 flex-shrink-0 items-center justify-center">
                                    <div
                                        class="h-1.5 w-1.5 rounded-full bg-muval-gray-4"
                                        :class="[subIsActive(subitem.route) && 'bg-muval-brand']"
                                    ></div>
                                </div>
                                <span
                                    v-if="navExpanded"
                                    class="text-sm text-muval-gray-1 opacity-100 transition duration-200 ease-in-out dark:text-gray-300"
                                    :class="[
                                        {
                                            'text-brand dark:text-brand': subIsActive(subitem.route),
                                        },
                                        { 'text-gray-800:': isActive },
                                    ]"
                                >
                                    {{ subitem.name }}
                                </span>
                                <div
                                    v-if="navExpanded && subitem.meta?.count"
                                    class="ml-2 rounded-md bg-yellow-100 px-2 py-0.5 text-xs font-bold text-yellow-500"
                                >
                                    {{ subitem.meta?.count }}
                                </div>
                            </div>
                        </router-link>
                    </DisclosurePanel>
                </template>
            </component>
        </template>
    </div>
</template>

<script setup>
import { useUserStore } from '@store/user';

import { useAppStore } from '@store/app';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const app = useAppStore();
const user = useUserStore();
const route = useRoute();
const router = useRouter();

const navExpanded = computed(() => {
    return app.side;
});

const tipDelay = computed(() => (navExpanded.value ? [900, 0] : [0, 0]));

watch(navExpanded, () => {
    tipDelay.value = navExpanded.value ? [900, 0] : [1, 0];
});

function subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    if (route.name == 'Order' && router?.options?.history?.state?.back == '/jobs' && input == '/jobs') {
        return true;
    } else if (route.name == 'Order' && router?.options?.history?.state?.back == '/jobs' && input == '/orders') {
        return false;
    } else {
        return paths.some((path) => {
            return route.path.indexOf(path) === 0; // current path starts with this path string
        });
    }
}

function modClick(route) {
    window.open(route, '_blank');
}

function canRefresh(input) {
    if (app.isMobile) {
        app.toggleSide();
    }
    return route.path == input;
}
</script>

<style>
.top-box {
    box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
}
.left-box {
    box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.4);
}
.right-box {
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
}
.bottom-box {
    box-shadow: inset 0 -7px 9px -7px rgba(0, 0, 0, 0.4);
}

:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
