import App from './App.vue';
import { createPinia } from 'pinia';
import { useUserStore } from '@store/user';
import { useAppStore } from '@store/app';
import resetStore from './store/reset-store';
import router from './router';
import Notifications from '@kyvg/vue3-notification';
import { VueQueryPlugin } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';

import '@lottiefiles/lottie-player';
import './style/tailwind.css';

// Plugins
import axios from './plugins/axios';
import echo from './plugins/echo';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import filters from './utilities/filters';

import 'virtual:svg-icons-register';
import VueTippy from 'vue-tippy';
import { VueReCaptcha } from 'vue-recaptcha-v3';

// Event Plugin
import mitt from 'mitt';
const emitter = mitt();

const pinia = createPinia();
const userStore = useUserStore(pinia);
const appStore = useAppStore(pinia);

// Allow router to be accessed in all pinia stores under this.router
pinia.use(({ store }) => {
    store.router = markRaw(router);
});
// Allow $reset to work with both setup and composition api stores
pinia.use(resetStore);

import SvgIcon from '@components/utilities/SvgIcon.vue';
import MuvalLoader from '@components/loader/MuvalLoader.vue';
import MuvalTippy from '@components/MuvalTippy.vue';
import RoleGuard from '@components/RoleGuard.vue';
import TransitionWrapper from '@components/TransitionWrapper.vue';
import MuvalButton from '@components/button/MuvalButton.vue';

if (import.meta.env.VITE_ENV == 'local') {
    appStore.setTestUsers();
}

userStore.fetchUser().then(() => {
    const app = createApp(App);

    // Register global components
    app.component('SvgIcon', SvgIcon)
        .component('MuvalLoader', MuvalLoader)
        .component('MuvalTippy', MuvalTippy)
        .component('RoleGuard', RoleGuard)
        .component('TransitionWrapper', TransitionWrapper)
        .component('MuvalButton', MuvalButton);

    app.use(pinia);
    app.use(axios);
    app.use(echo);

    app.config.globalProperties.emitter = emitter;
    app.config.globalProperties.$dayjs = dayjs;
    app.config.globalProperties.$filters = filters;

    app.use(VueReCaptcha, {
        siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
        loaderOptions: {
            autoHideBadge: true,
        },
    });

    app.use(Notifications);
    app.use(VueTippy, {
        directive: 'tippy', // => v-tippy
        component: 'Tippy', // => <tippy/>
        componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
    });
    app.use(router);

    // Vue query
    const vueQueryPluginOptions = {
        queryClientConfig: {
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                },
            },
        },
    };

    // only run Sentry in deployed environments
    if (
        import.meta.env.VITE_ENV == 'production' ||
        import.meta.env.VITE_ENV == 'testing' ||
        import.meta.env.VITE_ENV == 'development'
    ) {
        let reportingEnvironment = import.meta.env.VITE_RELEASE_BRANCH ?? import.meta.env.VITE_ENV;

        if (reportingEnvironment == 'main') {
            reportingEnvironment = 'production';
        }

        Sentry.init({
            app,
            dsn: 'https://7f2826facb8f476a9c22e6958c43f332@o4505112611454976.ingest.sentry.io/4505201664983040',
            environment: reportingEnvironment,
            integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 0.1,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.01,
            replaysOnErrorSampleRate: 1.0,
            ignoreErrors: [
                'Non-Error exception captured',
                'Non-Error promise rejection captured',
                'ResizeObserver loop limit exceeded',
                'Request failed with status code 422',
            ],
        });
    }

    app.use(VueQueryPlugin, vueQueryPluginOptions);

    app.mount('#app');
});
