<template>
    <div class="fixed top-0 hidden h-screen shadow md:flex md:flex-shrink-0">
        <div
            class="relative flex flex-col transition-all"
            :class="open ? 'w-[232px]' : 'w-[94px]'"
        >
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div
                class="relative flex flex-grow flex-col overflow-y-auto bg-white dark:bg-gray-900"
                @mouseenter="showToggle = true"
                @mouseleave="showToggle = false"
            >
                <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0"
                    enter-to-class="transform opacity-100"
                    leave-active-class="transition ease-in duration-200"
                    leave-from-class="transform opacity-100"
                    leave-to-class="transform opacity-0"
                >
                    <button
                        v-show="showToggle"
                        class="absolute top-3 z-1 flex h-10 w-10 rounded-muval-1 bg-white shadow-muval-1 focus:outline-none dark:bg-gray-800"
                        :class="open ? 'right-2' : 'right-6'"
                        @click="app.toggleSide()"
                    >
                        <svg
                            :class="[
                                { 'mr-2 rotate-180 transition-all duration-300': open },
                                { 'ml-2 transition-all duration-300': !open },
                            ]"
                            class="m-auto h-5 w-5 dark:text-white"
                            viewBox="0 0 20 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.1494 13L18.8063 7.34315L13.1494 1.68629"
                                stroke="currentColor"
                                stroke-opacity="0.5"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M7.65723 13L13.3141 7.34315L7.65723 1.68629"
                                stroke="currentColor"
                                stroke-opacity="0.5"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </transition>

                <div class="relative my-2 flex h-12 w-full pl-8">
                    <router-link
                        class="flex h-12 items-center justify-start overflow-hidden transition-all"
                        :class="open ? 'w-52' : 'w-10'"
                        to="/dashboard"
                    >
                        <SvgIcon
                            class="inline-flex h-auto w-32 flex-shrink-0 text-gray-900 dark:text-white"
                            name="logo"
                        />
                        <!-- <SvgIcon v-else class="h-6 -ml-6" name="mob_logo" /> -->
                    </router-link>
                </div>

                <div class="flex flex-grow flex-col">
                    <nav>
                        <NavItems />
                    </nav>
                </div>

                <MuvalHelp />

                <div
                    v-if="open"
                    class="flex"
                >
                    <p
                        class="mr-auto ml-2 max-h-8 cursor-default overflow-hidden p-1 text-center text-sm text-gray-300"
                    >
                        &copy; {{ new Date().getFullYear() }} Muval Pty Ltd
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAppStore } from '@store';
import MuvalHelp from '@/components/MuvalHelp.vue';

const app = useAppStore();
const open = computed(() => {
    return app.side;
});

const showToggle = ref(false);
</script>
