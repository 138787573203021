<template>
    <Transition
        enter-active-class="transition ease-out duration-600"
        enter-from-class="transform opacity-0 -translate-y-20"
        enter-to-class="transform opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="transform opacity-100"
        leave-to-class="transform opacity-0"
    >
        <div
            v-show="showBanner"
            class="absolute top-0 z-50 h-[4.75rem] w-full overflow-clip bg-gradient-to-t from-[#FF6002] to-[#FFA620] pl-[200px]"
        >
            <div class="relative flex h-full w-full justify-between">
                <div class="flex gap-5">
                    <img
                        src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
                        alt=""
                        class="-mt-8 -ml-5 h-32 w-32 rounded-full object-cover"
                    />
                    <div class="flex flex-col justify-center">
                        <span class="font-header text-2xl text-white">Inbound call record was smashed!</span>
                        <span class="text-xl text-white">
                            Congratulate Catherine for beating the record and taking 80 inbound calls!
                        </span>
                    </div>
                </div>
                <div class="mr-10 flex gap-5">
                    <div
                        class="my-auto flex h-14 w-14 cursor-pointer justify-center rounded-full bg-white shadow-xl hover:bg-muval-gray-5"
                    >
                        <span class="my-auto text-3xl">👏</span>
                    </div>
                    <div
                        class="my-auto flex h-14 w-14 cursor-pointer justify-center rounded-full bg-white shadow-xl hover:bg-muval-gray-5"
                    >
                        <span class="my-auto text-3xl">🎉</span>
                    </div>
                    <div
                        class="my-auto flex h-14 w-14 cursor-pointer justify-center rounded-full bg-white shadow-xl hover:bg-muval-gray-5"
                    >
                        <span class="my-auto text-3xl">💥</span>
                    </div>
                </div>
                <XIcon
                    class="absolute right-1 z-[55] w-6 cursor-pointer text-black"
                    @click.prevent="showBanner = false"
                />
            </div>
        </div>
    </Transition>
</template>
<script setup>
import { XIcon } from '@heroicons/vue/solid';

import useEmitter from '@plugins/useEmitter';

const showBanner = ref(false);
const emitter = useEmitter();
onMounted(() => {
    emitter.on('notification:banner', () => {
        showBanner.value = true;
    });
});
</script>
