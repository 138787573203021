<template>
    <Tippy
        :follow-cursor="true"
        :arrow="true"
        :delay="tipDelay"
        :duration="[200, 0]"
    >
        <div
            class="flex cursor-pointer items-center gap-4 overflow-x-clip py-1.5 pl-8 text-base font-normal text-muval-gray-1 no-underline hover:bg-muval-gray-6 hover:text-brand dark:text-gray-300"
            @click="showModal = true"
        >
            <SvgIcon
                class="h-8 w-8 flex-shrink-0"
                name="system-support"
            />
            <span v-if="navExpanded">Help</span>
        </div>
        <template #content>
            <div class="rounded bg-black bg-opacity-80 px-4 py-1 text-white shadow">Help</div>
        </template>
    </Tippy>

    <MuvalModal
        v-model="showModal"
        modalSize="md:w-2/3"
    >
        <template #header>Help</template>
        <div
            v-if="showCompanyHelp"
            class="flex flex-col gap-4"
        >
            <span>
                <span class="font-bold">Phone</span>
                -
                <a
                    href="tel:1300168825"
                    class="text-muval-sky-1"
                >
                    1300 168 825
                </a>
            </span>
            <div class="relative flex flex-col">
                <span class="font-bold">Email</span>
                <ul class="relative ml-5 list-disc">
                    <li>
                        <a
                            href="mailto:partners@muval.com.au"
                            class="text-muval-sky-1"
                        >
                            partners@muval.com.au
                        </a>
                        for support with your Dashboard eg info/rates updates, help with accepting/completing jobs
                    </li>
                    <li>
                        <a
                            href="mailto:support@muval.com.au"
                            class="text-muval-sky-1"
                        >
                            support@muval.com.au
                        </a>
                        for support with customer bookings (except accepting/completing jobs)
                    </li>
                </ul>
            </div>
            <div class="flex flex-col">
                <span class="font-bold">Hours</span>
                <ul class="relative ml-5 list-disc">
                    <li>Monday to Friday - 9am to 5pm</li>
                    <li>Saturday - 9am to 4pm</li>
                    <li>Sunday - Closed</li>
                </ul>
            </div>
        </div>
        <div v-else>
            <MuvalButton @click="clearCache">Clear cache</MuvalButton>
        </div>
        <div>
            <h2 class="mt-8 flex flex-row items-center text-lg font-medium">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    class="mr-2 h-6 w-6 text-muval-sky-1"
                    fill="currentColor"
                >
                    <path
                        d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"
                    />
                </svg>
                <template v-if="newRelease">New release available</template>
                <template v-else>Current release</template>
            </h2>
            <p class="mt-4 mb-2">
                <span class="text-sm">
                    <template v-if="newRelease">
                        Please save any work in progress and then refresh for the latest features and bug fixes. We
                        recommend you perform a force-refresh to be sure you're on the latest version.
                    </template>
                    <template v-else>
                        You appear to be on the current release, however if you want to be sure you're on the latest
                        version, we recommend you perform a force-refresh.
                    </template>
                </span>
            </p>
            <p class="mt-2 mb-4">
                <span class="text-sm">
                    Use Shift (⇧)-F5 on Windows or Cmd (⌘)-Shift (⇧)-R on macOS to force-refresh.
                </span>
            </p>

            <div class="flex gap-4">
                <MuvalButton @click="refresh()">Refresh</MuvalButton>

                <MuvalButton
                    secondary
                    @click="toggleDetails()"
                >
                    {{ showReleaseDetails ? 'Hide' : 'View' }} details
                </MuvalButton>
            </div>

            <template v-if="showReleaseDetails">
                <ReleaseDetails />
            </template>
        </div>
    </MuvalModal>
</template>

<script setup>
import { useQueryClient } from '@tanstack/vue-query';
import MuvalModal from '@components/modal/MuvalModal.vue';
import { useAppStore } from '@store/app';
import { notify } from '@kyvg/vue3-notification';

const guarded = ['COMPANY_MANAGER', 'COMPANY_AGENT'];

const userStore = useUserStore();

const showCompanyHelp = computed(() => {
    return userStore.hasAnyRole(guarded);
});

const app = useAppStore();
const showModal = ref(false);
const showReleaseDetails = ref(false);

const navExpanded = computed(() => {
    return app.side;
});

const newRelease = computed(() => {
    return app.newRelease;
});

const queryClient = useQueryClient();

function clearCache() {
    queryClient.clear();
    notify({
        title: 'Success',
        group: 'app_success',
        text: 'Cache cleared',
    });
}

function refresh() {
    window.location.reload(true);
}

function toggleDetails() {
    showReleaseDetails.value = !showReleaseDetails.value;
}
</script>
