<template>
    <table
        v-if="latestRelease?.release && newRelease"
        class="mt-4"
    >
        <caption class="pb-2 text-left text-base font-medium">
            Latest release
        </caption>
        <tbody>
            <tr
                v-for="(releaseValue, releaseField) in latestRelease"
                :key="`release-${releaseField}`"
            >
                <th class="py-0.5 text-sm">{{ $filters.humanize(releaseField) }}</th>
                <td class="py-0.5 pl-4 font-mono text-xs">{{ releaseValue }}</td>
            </tr>
        </tbody>
    </table>
    <table
        v-if="currentRelease?.release"
        class="mt-4"
    >
        <caption class="pb-2 text-left text-base font-medium">
            Your release
        </caption>
        <tbody>
            <tr
                v-for="(releaseValue, releaseField) in currentRelease"
                :key="`release-${releaseField}`"
            >
                <th class="py-0.5 text-sm">{{ $filters.humanize(releaseField) }}</th>
                <td class="py-0.5 pl-4 font-mono text-xs">{{ releaseValue }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { useAppStore } from '@store/app';

const app = useAppStore();

const currentRelease = computed(() => {
    const release = app.currentRelease;

    delete release.release_commit_url;

    return release;
});

const latestRelease = computed(() => {
    const release = app.latestRelease;

    delete release.release_commit_url;

    return release;
});

const newRelease = computed(() => {
    return app.newRelease;
});
</script>
