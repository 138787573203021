<template>
    <div
        :class="cssVars"
        class="flex h-screen font-body text-muval-gray-1 dark:bg-gray-900 dark:text-white"
    >
        <MobileSidebar v-if="showApp && isMobile" />
        <DesktopSidebar
            v-if="showApp && !isMobile"
            class="fixed top-0 z-50"
        />

        <!-- Notification Side -->
        <NotificationSlideOver
            v-if="showApp && user.isAuthenticated"
            class="z-40"
        />
        <div
            class="relative z-0 flex w-0 flex-1 flex-col mxw:mx-auto mxw:max-w-[1920px]"
            :class="[showApp ? 'h-[calc(100%-68px)]' : 'h-full', { 'blur-sm': modalBlur }]"
        >
            <TopHeader v-if="showApp" />
            <main class="relative h-full flex-1 focus:outline-none">
                <div
                    class="h-full"
                    :class="showApp ? `mx-auto px-0 sm:px-6 md:px-8` : ''"
                >
                    <div
                        class="relative h-full pt-4 transition-all"
                        :class="{
                            'pb-12': showApp,
                            'ml-[232px]': open && showApp && !isMobile,
                            'ml-[94px]': !open && !isMobile,
                        }"
                    >
                        <template v-if="isMuvalUser">
                            <RouterView
                                v-slot="{ Component }"
                                name="agent"
                            >
                                <transition
                                    name="fade"
                                    mode="out-in"
                                >
                                    <component :is="Component" />
                                </transition>
                            </RouterView>
                        </template>
                        <template v-else>
                            <RouterView v-slot="{ Component }">
                                <!-- <transition name="fade" mode="out-in"> -->
                                <component :is="Component" />
                                <!-- </transition> -->
                            </RouterView>
                        </template>
                    </div>
                </div>
            </main>
        </div>
    </div>

    <ToastNotification
        v-if="showApp"
        class="z-50"
    />
</template>

<script setup>
import useEmitter from '@plugins/useEmitter';

// Components
import DesktopSidebar from '@components/DesktopSidebar.vue';
import MobileSidebar from '@components/MobileSidebar.vue';
import TopHeader from '@components/TopHeader.vue';
import NotificationSlideOver from '@components/notification/NotificationSlideOver.vue';
import ToastNotification from '@components/ToastNotification.vue';
import events from './events/events.js';

events();

const app = useAppStore();
const user = useUserStore();
const emitter = useEmitter();
const open = computed(() => {
    if (!user.isAuthenticated) {
        return true;
    }
    return app.side;
});

const isMobile = computed(() => {
    return app.isMobile;
});

const isMuvalUser = computed(() => {
    return user.hasAnyRole(['MUVAL_AGENT', 'MUVAL_ADMIN', 'MUVAL_TRIAGE', 'MUVAL_COMPANY', 'SUPER']);
});

// const styleObject = computed(() => {
//   if (isMobile.value) {
//     return {
//       height: "100vh",
//     };
//   }
//   return {
//     height: "calc(100vh - 78.8px)",
//   };
// });

// Sets dark mode on body tag in index.html
const cssVars = computed(() => {
    if (app.theme) {
        document.getElementById('bodyId').className = 'dark';
    } else {
        document.getElementById('bodyId').className = '';
    }
});

// Boolean check if/should render Top and Side navigation
const route = useRoute();
const showApp = computed(() => {
    if (
        user.isAuthenticated &&
        route.name !== 'Login' &&
        route.name !== 'Register' &&
        route.name !== 'Update your preferences'
    ) {
        return true;
    } else {
        return false;
    }
});

const modalBlur = ref(false);
onMounted(() => {
    app.setDeviceType();
    emitter.on('modal:blur', (val) => {
        modalBlur.value = val;
    });
});

onMounted(async () => {
    await app.initGoogle();
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
