<template>
    <Menu
        as="div"
        class="relative z-50"
    >
        <MenuButton
            class="m-auto flex h-10 w-10 items-center rounded-full border-2 px-0 text-sm focus:outline-none dark:hover:bg-gray-800 md:w-auto md:rounded-lg md:border-none md:px-3"
        >
            <span class="sr-only">Open user menu</span>
            <span class="hidden rounded-none p-2 text-xs md:block md:text-sm">
                {{ user.getFullName }}
            </span>

            <div class="m-auto">
                <span class="block rounded-none p-1 text-xs md:hidden md:text-sm">
                    {{ firstLetter(user.getFirstName) }}. {{ firstLetter(user.getLastName) }}
                </span>
            </div>

            <ChevronDownIcon
                class="ml-2 -mr-1 hidden h-5 w-5 text-muval-gray-1 hover:text-muval-gray-2 md:block"
                aria-hidden="true"
            />
        </MenuButton>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 mt-2 w-48 origin-top-right rounded-muval-1 bg-white py-1 shadow-muval-1 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800"
            >
                <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                    as="div"
                    class="flex:col flex"
                >
                    <div
                        class="flex w-full transition"
                        :class="active ? 'bg-muval-gray-6 text-brand dark:bg-gray-600' : ''"
                    >
                        <SvgIcon
                            class="my-auto ml-3 h-5 w-5 transition"
                            :name="item.icon"
                            :class="active ? 'bg-gray-100 dark:bg-gray-600' : 'text-muval-gray-1'"
                        />
                        <router-link
                            class="block w-full px-4 py-2 text-sm text-gray-700 dark:text-white"
                            :to="item.to"
                        >
                            {{ item.name }}
                        </router-link>
                    </div>
                </MenuItem>

                <div class="my-2 mx-2 h-0 border" />

                <MenuItem
                    v-slot="{ active }"
                    as="div"
                    class="flex flex-col"
                >
                    <div
                        class="flex cursor-pointer transition"
                        :class="active ? 'bg-muval-gray-6 text-brand dark:bg-gray-600' : 'text-muval-gray-1'"
                        @click="logout"
                    >
                        <svg
                            class="my-auto ml-4 h-4 w-4 transition"
                            width="18"
                            height="16"
                            viewBox="0 0 18 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.167 3.83333L11.992 5.00833L14.142 7.16667H5.66699V8.83333H14.142L11.992 10.9833L13.167 12.1667L17.3337 8L13.167 3.83333ZM2.33366 2.16667H9.00033V0.5H2.33366C1.41699 0.5 0.666992 1.25 0.666992 2.16667V13.8333C0.666992 14.75 1.41699 15.5 2.33366 15.5H9.00033V13.8333H2.33366V2.16667Z"
                                fill="currentColor"
                            />
                        </svg>

                        <button :class="['block px-4 py-2 text-sm text-gray-700 dark:text-white']">Logout</button>
                    </div>
                </MenuItem>
                <!-- todo enable -->
                <!-- <div class="flex w-full justify-between px-4 py-2">
          <span class="text-sm">Dark Mode</span>
          <MuvalSwitch v-model="theme" />
        </div> -->
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import { firstLetter } from '@utilities/filters';
import { uniqBy } from 'lodash';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';

// Stores
// import { useAppStore } from "@store/app";
import { useUserStore } from '@store/user';

const allUsersNavigation = [{ name: 'My Account', to: '/me', icon: 'account' }];

const removalistUsersNavigation = [
    { name: 'Company Profile', to: '/company/profile', icon: 'profile' },
    { name: 'Company Settings', to: '/company/settings', icon: 'setting' },
];

// const app = useAppStore();
const user = useUserStore();
const router = useRouter();

const userNavigation = computed(() => {
    let navigation = allUsersNavigation;
    user.getUser.roles.forEach((role) => {
        switch (role) {
            case 'MUVAL_AGENT':
                break;
            case 'MUVAL_ADMIN':
                break;
            case 'COMPANY_MANAGER':
                navigation = [...navigation, ...removalistUsersNavigation];
                break;
        }
    });

    return uniqBy(navigation, 'name');
});

async function logout() {
    await user.logout();
    router.replace({ path: '/login' });
}
</script>
