export default [
    {
        path: '/communication',
        name: 'CommunicationIndex',
        meta: {
            requiresAuth: true,
            title: 'Communication',
        },
        components: {
            default: () => import('@views/communication/CommunicationsIndex.vue'),
            agent: () => import('@views/communication/CommunicationsIndex.vue'),
        },
    },
    {
        path: '/communication/create',
        name: 'CommunicationCreate',
        meta: {
            requiresAuth: true,
            title: 'Create communication template',
        },
        props: {
            default: () => ({ isCreate: true }),
            agent: () => ({ isCreate: true }),
        },
        components: {
            default: () => import('@views/communication/CommunicationsForm.vue'),
            agent: () => import('@views/communication/CommunicationsForm.vue'),
        },
    },
    {
        path: '/communication/:id/edit',
        name: 'CommunicationEdit',
        meta: {
            requiresAuth: true,
            title: 'Edit communication template',
        },
        props: {
            default: () => ({ isCreate: false }),
            agent: () => ({ isCreate: false }),
        },
        components: {
            default: () => import('@views/communication/CommunicationsForm.vue'),
            agent: () => import('@views/communication/CommunicationsForm.vue'),
        },
    },
];
