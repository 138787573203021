<template>
    <div
        class="sticky top-0 z-40 flex bg-app transition-all dark:bg-gray-900"
        :class="{
            'ml-[232px]': app.side && !app.isMobile,
            'ml-[94px]': !app.side && !app.isMobile,
        }"
    >
        <div class="mt-3 mb-4 flex w-full">
            <button
                v-if="app.isMobile"
                class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none"
                @click="app.toggleSide()"
            >
                <span class="sr-only">Open sidebar</span>
                <MenuAlt2Icon
                    class="h-6 w-6"
                    aria-hidden="true"
                />
            </button>
            <div class="flex w-full items-center justify-between px-4 md:pl-4">
                <!-- Side Menu Shrink -->
                <!-- <button @click="app.toggleSide()" class="hidden md:block hover:bg-gray-50 dark:hover:bg-gray-700 rounded-full focus:outline-none focus:ring-2 ring-brand h-8 w-8 m-auto">
                <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
            </button> -->

                <div class="flex grow truncate whitespace-nowrap font-header text-2xl font-medium md:block md:px-4">
                    <div
                        id="topHeader"
                        ref="teleporter"
                    ></div>
                    <template v-if="!teleporter?.children?.length">
                        <div
                            v-if="route.name === 'Interstate' && !isAdmin"
                            class="flex"
                        >
                            Interstate
                            <router-link
                                class="my-auto ml-4"
                                to="/interstate/settings"
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.926 8.78333C13.9593 8.53333 13.976 8.275 13.976 8C13.976 7.73333 13.9593 7.46667 13.9177 7.21667L15.6093 5.9C15.7593 5.78333 15.801 5.55833 15.7093 5.39167L14.1093 2.625C14.0093 2.44167 13.801 2.38333 13.6177 2.44167L11.626 3.24167C11.2093 2.925 10.7677 2.65833 10.276 2.45833L9.976 0.341667C9.94267 0.141667 9.776 0 9.576 0H6.376C6.176 0 6.01767 0.141667 5.98434 0.341667L5.68434 2.45833C5.19267 2.65833 4.74267 2.93333 4.33434 3.24167L2.34267 2.44167C2.15934 2.375 1.951 2.44167 1.851 2.625L0.259336 5.39167C0.159336 5.56667 0.192669 5.78333 0.359336 5.9L2.051 7.21667C2.00934 7.46667 1.976 7.74167 1.976 8C1.976 8.25833 1.99267 8.53333 2.03434 8.78333L0.342669 10.1C0.192669 10.2167 0.151003 10.4417 0.24267 10.6083L1.84267 13.375C1.94267 13.5583 2.151 13.6167 2.33434 13.5583L4.326 12.7583C4.74267 13.075 5.18434 13.3417 5.676 13.5417L5.976 15.6583C6.01767 15.8583 6.176 16 6.376 16H9.576C9.776 16 9.94267 15.8583 9.96767 15.6583L10.2677 13.5417C10.7593 13.3417 11.2093 13.075 11.6177 12.7583L13.6093 13.5583C13.7927 13.625 14.001 13.5583 14.101 13.375L15.701 10.6083C15.801 10.425 15.7593 10.2167 15.601 10.1L13.926 8.78333ZM7.976 11C6.326 11 4.976 9.65 4.976 8C4.976 6.35 6.326 5 7.976 5C9.626 5 10.976 6.35 10.976 8C10.976 9.65 9.626 11 7.976 11Z"
                                        fill="#808080"
                                    />
                                </svg>
                            </router-link>
                        </div>
                        <div
                            v-else-if="route.name === 'Companies'"
                            class="flex text-2xl font-medium"
                        >
                            Companies
                            <router-link
                                v-if="false"
                                to="/"
                                class="my-auto ml-2 flex items-center space-x-2 rounded-sm bg-gray-100 p-1.5"
                            >
                                <svg
                                    width="13"
                                    height="13"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.56999 5.46995C8.58999 5.31995 8.59999 5.16495 8.59999 4.99995C8.59999 4.83995 8.58999 4.67995 8.56499 4.52995L9.57999 3.73995C9.66999 3.66995 9.69499 3.53495 9.63999 3.43495L8.67999 1.77495C8.61999 1.66495 8.49499 1.62995 8.38499 1.66495L7.18999 2.14495C6.93999 1.95495 6.67499 1.79495 6.37999 1.67495L6.19999 0.404951C6.17999 0.284951 6.07999 0.199951 5.95999 0.199951H4.03999C3.91999 0.199951 3.82499 0.284951 3.80499 0.404951L3.62499 1.67495C3.32999 1.79495 3.05999 1.95995 2.81499 2.14495L1.61999 1.66495C1.50999 1.62495 1.38499 1.66495 1.32499 1.77495L0.369994 3.43495C0.309994 3.53995 0.329994 3.66995 0.429994 3.73995L1.44499 4.52995C1.41999 4.67995 1.39999 4.84495 1.39999 4.99995C1.39999 5.15495 1.40999 5.31995 1.43499 5.46995L0.419994 6.25995C0.329994 6.32995 0.304994 6.46495 0.359994 6.56495L1.31999 8.22495C1.37999 8.33495 1.50499 8.36995 1.61499 8.33495L2.80999 7.85495C3.05999 8.04495 3.32499 8.20495 3.61999 8.32495L3.79999 9.59495C3.82499 9.71495 3.91999 9.79995 4.03999 9.79995H5.95999C6.07999 9.79995 6.17999 9.71495 6.19499 9.59495L6.37499 8.32495C6.66999 8.20495 6.93999 8.04495 7.18499 7.85495L8.37999 8.33495C8.48999 8.37495 8.61499 8.33495 8.67499 8.22495L9.63499 6.56495C9.69499 6.45495 9.66999 6.32995 9.57499 6.25995L8.56999 5.46995ZM4.99999 6.79995C4.00999 6.79995 3.19999 5.98995 3.19999 4.99995C3.19999 4.00995 4.00999 3.19995 4.99999 3.19995C5.98999 3.19995 6.79999 4.00995 6.79999 4.99995C6.79999 5.98995 5.98999 6.79995 4.99999 6.79995Z"
                                        fill="#393939"
                                    />
                                </svg>
                                <span class="text-xs">Pricing options</span>
                            </router-link>
                        </div>
                        <h1
                            v-else
                            class="truncate text-2xl font-medium"
                        >
                            {{ headerInfo }}
                        </h1>
                    </template>
                </div>

                <div class="relative z-50 ml-auto flex w-fit shrink-0 items-center md:ml-4">
                    <div class="flex gap-2">
                        <RoleGuard :guarded="['MUVAL_ADMIN', 'MUVAL_AGENT', 'MUVAL_TRIAGE', 'SUPER']">
                            <BookingStatus />
                            <SearchModal />
                            <CallsIcon :count="badgeCount.phone" />
                            <SmsIcon :count="badgeCount.text" />
                        </RoleGuard>
                        <NotificationIcon />
                    </div>

                    <!-- Profile dropdown -->
                    <ProfileDropDown />
                </div>
            </div>
        </div>
    </div>
    <BannerNotification />
</template>

<script setup>
import ProfileDropDown from '@components/dropdown/ProfileDropDown.vue';
import CallsIcon from '@components/calls/CallsIcon.vue';
import SmsIcon from '@components/sms/SmsIcon.vue';
import BookingStatus from '@components/BookingStatus.vue';

import NotificationIcon from '@components/notification/NotificationIcon.vue';
import { startCase } from 'lodash';

import { MenuAlt2Icon } from '@heroicons/vue/outline';

import BannerNotification from './BannerNotification.vue';
import { useDepotStore, useCompanyStore, useAppStore, useUserStore, useJobStore, useTenderStore } from '@store';
import useEmitter from '@plugins/useEmitter';
import { onMounted, onUnmounted } from 'vue';

const app = useAppStore();
const route = useRoute();
const userStore = useUserStore();
const depotStore = useDepotStore();
const jobStore = useJobStore();
const tenderStore = useTenderStore();

const isAdmin = computed(() => {
    return userStore.isAdmin;
});

const companyName = computed(() => {
    const company = useCompanyStore();
    return company?.data?.name ?? null;
});

const headerInfo = computed(() => {
    switch (route.name) {
        case 'Dashboard':
            return userStore.getFirstName ? `Hi ${userStore.getFirstName}!` : null;
        case 'Search':
            return `Search ${userStore.search ?? ''}`;
        // Leads
        case 'LeadCreate':
            return 'Create Lead';
        // Interstate
        case 'InterstateTripCreate':
            return 'Create Interstate Trip';
        case 'InterstateTripEdit':
            return 'Edit Interstate Trip';
        case 'InterstateLaneCreate':
            return 'Create Interstate Lane';
        case 'InterstateLaneEdit':
            return 'Edit Interstate Lane';
        // Depots
        case 'DepotsCreate':
            return 'Create Depot';
        case 'DepotsEdit':
            return `Edit ${depotStore?.data?.name ?? 'depot'}`;
        // Companies
        case 'CompaniesView':
            return companyName.value;
        case 'CompaniesEdit':
            return 'Edit Company Profile';
        case 'CompaniesPreferences':
            return 'Company Preferences';
        // Local
        case 'LocalCreate':
            return 'Create Local';
        case 'LocalEdit':
            return 'Edit Local';
        // Fleet
        case 'FleetCreate':
            return 'Create Vehicle';
        case 'FleetEdit':
            return 'Edit Vehicle';
        // Staff
        case 'StaffCreate':
            return 'Create Staff Member';
        case 'StaffEdit':
            return 'Edit Staff Member';
        case 'CompanyStaffEdit':
            return 'Edit Staff Member';
        // SMS & CALL
        case 'InboxCalls':
            return 'Calls';
        case 'InboxSms':
            return 'Inbox';
        // Comms
        case 'CommunicationIndex':
            return 'Communication';
        case 'CommunicationCreate':
            return 'Create Communication Template';
        case 'CommunicationEdit':
            return 'Edit Communication Template';
        // Jobs
        case 'JobView':
            return jobStore?.data?.order_ref ? `Job #${jobStore?.data?.order_ref ?? ''}` : '';
        // Quotes
        case 'QuoteView':
            return tenderStore?.tender?.order_ref ? `Quote #${tenderStore?.tender?.order_ref ?? ''}` : '';
        // Errors
        case '404Page':
            return '';
        default:
            return startCase(route.name);
    }
});

watch(
    headerInfo,
    () => {
        document.title = headerInfo.value ? `Muval - ${headerInfo.value}` : 'Muval';
    },
    { immediate: true },
);

onMounted(() => {
    document.onkeydown = function (e) {
        if (e.keyCode === 75 && e.ctrlKey) {
            return false;
        } else {
            return;
        }
    };
});

const badgeCount = reactive({
    text: 0,
    phone: 0,
});

const getInboxCount = async () => {
    const { data: text } = await window.axios.get('inbox', {
        params: {
            count: 1,
            direction: 'INCOMING',
            seen: 0,
            type: 'TEXT',
            ...(userStore.getTeam != 'NONE' &&
                userStore.getTeam && {
                    team: userStore.getTeam,
                }),
        },
    });
    const { data: phone } = await window.axios.get('inbox', {
        params: {
            count: 1,
            direction: 'INCOMING',
            seen: 0,
            type: 'PHONE',
            ...(userStore.getTeam != 'NONE' &&
                userStore.getTeam && {
                    team: userStore.getTeam,
                }),
        },
    });

    badgeCount.text = text.count;
    badgeCount.phone = phone.count;
};

const emitter = useEmitter();

onMounted(() => {
    if (userStore.isAdmin || userStore.isAgent || userStore.isTriage) {
        getInboxCount();
        let stop = setInterval(getInboxCount, 60000);
        onUnmounted(() => {
            clearInterval(stop);
        });

        // When call/text items are marked as seen, refetch the notification count
        emitter.on('update:seen', getInboxCount);
    }
});

const teleporter = ref();
</script>
