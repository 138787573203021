export default [
    {
        path: '/affiliates',
        name: 'Affiliates',
        meta: {
            requiresAuth: true,
            title: 'Affiliates',
        },
        components: {
            default: () => import('@views/affiliates/AffiliatesIndex.vue'),
            agent: () => import('@views/affiliates/AffiliatesIndex.vue'),
        },
    },
    {
        path: '/affiliates/create',
        name: 'AffiliatesCreate',
        props: {
            default: () => ({ isCreate: true }),
            agent: () => ({ isCreate: true }),
        },
        meta: {
            requiresAuth: true,
            title: 'Create Affiliate',
        },
        components: {
            default: () => import('@views/affiliates/AffiliatesForm.vue'),
            agent: () => import('@views/affiliates/AffiliatesForm.vue'),
        },
    },
    {
        path: '/affiliates/:id/edit',
        name: 'AffiliatesEdit',
        props: {
            default: (route) => ({ id: route.params.id, isCreate: false }),
            agent: (route) => ({ id: route.params.id, isCreate: false }),
        },
        meta: {
            requiresAuth: true,
            title: 'Edit Affiliate',
        },
        components: {
            default: () => import('@views/affiliates/AffiliatesForm.vue'),
            agent: () => import('@views/affiliates/AffiliatesForm.vue'),
        },
    },
];
