<template>
    <div class="relative hidden md:flex">
        <button
            class="shrink-0 rounded-full p-2 text-gray-400 hover:bg-muval-gray-5 hover:text-gray-500 focus:outline-none dark:hover:bg-gray-700"
            @click="app.toggleNotificationSlider"
        >
            <span class="sr-only">View notifications</span>
            <template v-if="notificationCount > 0">
                <div
                    class="absolute top-0 right-2 animate-ping rounded-full bg-brand px-1 text-xs font-bold text-white"
                >
                    {{ notificationCount }}
                </div>
                <div class="absolute top-0 right-2 rounded-full bg-brand px-1 text-xs font-bold text-white">
                    {{ notificationCount }}
                </div>
            </template>
            <SvgIcon
                class="h-5 w-5 text-[#393939]"
                name="notifications"
            />
        </button>
    </div>
</template>

<script setup>
import { useAppStore } from '@store/app';
import { useNotificationsStore } from '@store/notifications';

const app = useAppStore();
const notifications = useNotificationsStore();

const notificationCount = computed(() => {
    return notifications.getUnreadCount;
});

onMounted(() => {
    notifications.startListener();
});
</script>
