import useEmitter from '@plugins/useEmitter';

export default function events() {
    const events = useEmitter();
    const listeners = {
        'order:move:matches:update': OrderMoveMatchesUpdate,
    };
    Object.entries(listeners).forEach(([key, val]) => events.on(key, val));
}

async function OrderMoveMatchesUpdate(callback) {
    console.log('OrderMoveMatchesUpdate', callback);
    try {
        const orderStore = useOrderStore();
        await orderStore.getTotals();
    } catch (e) {
        console.error('OrderMoveMatchesUpdate', e);
    } finally {
        callback();
    }
}
