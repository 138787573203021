import { defineStore } from 'pinia';
import AffiliateAPI from '@api/affiliate';

export const useAffiliateStore = defineStore('affiliate', () => {
    const affiliate = ref();

    async function retrieveAffiliate(id) {
        const response = await AffiliateAPI.retrieve(id);
        affiliate.value = response;
    }

    async function createAffiliate(id) {
        const response = await AffiliateAPI.create(id);
        affiliate.value = response;
    }

    async function updateAffiliate(id, values) {
        const response = await AffiliateAPI.update(id, values);
        affiliate.value = response;
    }

    async function createToken(id) {
        const response = await AffiliateAPI.token(id);
        affiliate.value.auth_token = response.data.plainTextToken;
    }

    return {
        affiliate,
        retrieveAffiliate,
        createAffiliate,
        updateAffiliate,
        createToken,
    };
});
