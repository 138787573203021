export function useStatusColours(status, options = { type: null }) {
    const textStyle = ref('');
    const bgStyle = ref('');
    let stat;

    function execute() {
        stat = unref(status);
        textStyle.value = '';
        bgStyle.value = '';
        switch (options?.type?.toUpperCase()) {
            case 'ORDER':
                switch (stat?.toUpperCase()) {
                    default:
                        textStyle.value = 'text-muval-gray-1';
                        bgStyle.value = 'bg-muval-gray-5';
                        break;
                }
                break;
            case 'ICON':
                switch (stat?.toUpperCase()) {
                    case 'READY':
                        textStyle.value = 'text-muval-dusk-2';
                        break;
                    case 'ACCEPTED':
                        textStyle.value = 'text-muval-green-1';
                        break;
                    case 'PENDING':
                        textStyle.value = 'text-muval-amber';
                        break;
                    case 'PROCESSING':
                        textStyle.value = 'text-muval-blue-1';
                        break;
                    case 'PAID':
                        textStyle.value = 'text-muval-success';
                        break;
                    case 'FAILED':
                        textStyle.value = 'text-muval-red-1';
                        break;
                    case 'QUOTED':
                        textStyle.value = 'text-muval-purple-1';
                        break;
                    default:
                        textStyle.value = 'text-muval-gray-3';
                        break;
                }
                break;
            case 'PAYOUT':
                switch (stat?.toUpperCase()) {
                    case 'PENDING':
                        textStyle.value = 'text-muval-brand';
                        bgStyle.value = 'bg-muval-amber/30';
                        break;
                    case 'QUEUED':
                        textStyle.value = 'text-muval-purple-1';
                        bgStyle.value = 'bg-muval-purple-2';
                        break;
                    case 'PAUSED':
                        textStyle.value = 'text-muval-brown-1';
                        bgStyle.value = 'bg-muval-brown-2';
                        break;
                    case 'PROCESSING':
                        textStyle.value = 'text-muval-dusk-2';
                        bgStyle.value = 'bg-muval-sky-2';
                        break;
                    case 'PROCESSED':
                        textStyle.value = 'text-muval-dusk-1';
                        bgStyle.value = 'bg-muval-green-2';
                        break;
                    case 'PAID':
                        textStyle.value = 'text-muval-green-1';
                        bgStyle.value = 'bg-muval-green-2';
                        break;
                    case 'FAILED':
                        textStyle.value = 'text-muval-error';
                        bgStyle.value = 'bg-muval-red-2';
                        break;
                    case 'CANCELLED':
                        textStyle.value = 'text-muval-error';
                        bgStyle.value = 'bg-muval-red-2';
                        break;
                    default:
                        textStyle.value = 'text-muval-gray-1';
                        bgStyle.value = 'bg-muval-gray-4';
                        break;
                }
                break;
            case 'COMPANY':
                switch (stat?.toUpperCase()) {
                    case 'PENDING':
                        textStyle.value = 'text-yellow-800';
                        bgStyle.value = 'bg-yellow-100';
                        break;
                    case 'ONBOARDED':
                        textStyle.value = 'text-teal-800';
                        bgStyle.value = 'bg-teal-100';
                        break;
                    case 'VETTED':
                        textStyle.value = 'text-green-800';
                        bgStyle.value = 'bg-green-100';
                        break;
                    case 'REJECTED':
                        textStyle.value = 'text-pink-800';
                        bgStyle.value = 'bg-pink-100';
                        break;
                    case 'RESTRICTED':
                        textStyle.value = 'text-purple-800';
                        bgStyle.value = 'bg-purple-100';
                        break;
                    case 'REMOVED':
                        textStyle.value = 'text-red-800';
                        bgStyle.value = 'bg-red-100';
                        break;
                    default:
                        textStyle.value = 'text-muval-gray-1';
                        bgStyle.value = 'bg-muval-gray-4';
                        break;
                }
                break;
            default:
                switch (stat?.toUpperCase()) {
                    case 'NO MATCHES':
                        textStyle.value = 'text-muval-brown-1';
                        bgStyle.value = 'bg-muval-brown-2';
                        break;
                    case 'QUOTED':
                        textStyle.value = 'text-muval-purple-1';
                        bgStyle.value = 'bg-muval-purple-2';
                        break;
                    case 'PENDING':
                        textStyle.value = 'text-muval-brand';
                        bgStyle.value = 'bg-muval-amber/30';
                        break;
                    case 'ACCEPTED':
                        textStyle.value = 'text-muval-green-1';
                        bgStyle.value = 'bg-muval-green-2';
                        break;
                    case 'READY':
                        textStyle.value = 'text-muval-dusk-2';
                        bgStyle.value = 'bg-muval-sky-2';
                        break;
                    case 'COMPLETED':
                        textStyle.value = 'text-muval-lime-1';
                        bgStyle.value = 'bg-muval-lime-2';
                        break;
                    case 'DECLINED':
                        textStyle.value = 'text-muval-error';
                        bgStyle.value = 'bg-muval-red-2';
                        break;
                    case 'ON HOLD':
                        textStyle.value = 'text-muval-error';
                        bgStyle.value = 'bg-muval-red-2';
                        break;
                    case 'URGENT':
                        textStyle.value = 'text-muval-pink-1';
                        bgStyle.value = 'bg-muval-pink-2';
                        break;
                    case 'BOOK NOW':
                        textStyle.value = 'text-muval-purple-1';
                        bgStyle.value = 'bg-muval-purple-2';
                        break;
                    case 'BROWSING':
                        textStyle.value = 'text-muval-green-1';
                        bgStyle.value = 'bg-muval-lime-2';
                        break;
                    case 'DISCUSS OPTIONS':
                        textStyle.value = 'text-muval-green-1';
                        bgStyle.value = 'bg-muval-green-2';
                        break;
                    case 'THREE MONTHS PLUS':
                        textStyle.value = 'text-muval-blue-1';
                        bgStyle.value = 'bg-muval-blue-2';
                        break;
                    case 'REFERRAL':
                        textStyle.value = 'text-muval-dusk-2';
                        bgStyle.value = 'bg-muval-blue-2';
                        break;
                    case 'RETURN CUSTOMER':
                        textStyle.value = 'text-muval-dusk-1';
                        bgStyle.value = 'bg-muval-green-2';
                        break;
                    default:
                        textStyle.value = 'text-muval-gray-1';
                        bgStyle.value = 'bg-muval-gray-4';
                }
                break;
        }
    }

    watch(() => unref(status), execute, {
        immediate: true,
    });

    return { textStyle, bgStyle };
}
